<template>
	<div
		v-if="logged_user.customer_permission === 'ADMIN'"
		class="settings-wrapper"
	>
		<div class="outbound-inbound-routing-items q-pt-md">
			<q-input
				outlined
				v-model="value"
				:label="input_item.label"
				:name="input_item.name"
				:hint="input_item.helper_text"
			/>
			<details-button
				:tooltip_content="input_item.tooltip_content"
				:name="input_item.name"
			/>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import DetailsButton from '@/components/datasets/edit/settings/DetailsButton.vue'

export default {
	name: 'OpeningClosingContent',
	components: {
		DetailsButton,
	},
	props: {
		input_item: Object,
	},
	computed: {
		...mapGetters(['logged_user']),
		value: {
			get() {
				return this.$store.getters[this.input_item.value]
			},
			set(val) {
				this.$store.dispatch(this.input_item.action, val)
			},
		},
	},
}
</script>
