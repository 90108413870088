<template>
	<div>
		<div
			v-for="switch_item in switch_options"
			:key="switch_item.label"
		>
			<message-sending-switch
				v-if="switch_item.condition"
				:switch_item="switch_item"
			/>
		</div>

		<div
			v-for="input_item in input_options"
			:key="input_item.label"
		>
			<opening-closing-content :input_item="input_item" />
		</div>

		<div
			v-if="logged_user.customer_permission === 'ADMIN'"
			class="settings-wrapper"
		>
			<div class="outbound-inbound-routing-items q-pt-md">
				<q-select
					v-model="default_message_timeout"
					:options="timeout_choices"
					label="Duration"
					name="on-time-response-duration"
					hint="Default on time response duration"
				/>
				<details-button
					:tooltip_content="timeout_tooltip_content"
					name="default-timeout"
				/>
			</div>
		</div>
	</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import helpers from '@/utils/helpers.js'
import MessageSendingSwitch from '@/components/datasets/edit/settings/MessageSendingSwitch.vue'
import OpeningClosingContent from '@/components/datasets/edit/settings/OpeningClosingContent.vue'
import DetailsButton from '@/components/datasets/edit/settings/DetailsButton.vue'

export default {
	name: 'MessageSending',
	components: {
		MessageSendingSwitch,
		OpeningClosingContent,
		DetailsButton,
	},
	data() {
		return {
			timeout_choices: [],
			show_send_notifications_to_admins_about_invalid_recipients: false,
			sms_quick_reports_tooltip_content: `By default, reports recipients will receive links to their reports via their work email address. Turning
			this slider on means that reports recipients will also receive links to their reports via SMS to all SMS enabled devices associated with their
			profile.`,
			test_message_header_tooltip_content: `Automatically inserts “${this.$store.getters.customer_group.customer_group.session_type_name.test_scenario_name}”
			at the beginning of all SMS messages that are sent as type TEST. No content will be inserted to message that are sent as type REAL.`,
			send_notifications_to_admins_about_invalid_recipients_tooltip_content: `When we receive notification from email and/or telephony carriers that
			the contact details for a recipient are invalid, you can choose to automatically inform Admin level users about this via email. All Admins will
			receive these notifications, and they will receive an email per invalid contact detail.`,
			two_way_sms_tooltip_content: `Due to local regulations and infrastructure restrictions, some locations do not fully support traditional 2
			way SMS. This means that recipients in these locations will not be able to reply. Enabling this slider means that where we detect you are
			sending a 2 way message to an unsupported location, a clickable link will be embedded into your SMS allowing your recipient to respond via
			the link instead. All other message recipients will continue to be able to respond in the usual manner.`,
			opening_text_tooltip_content: `Maximum 50 characters. Custom content created here will be automatically inserted at the beginning of all
			outbound voice calls and SMS.`,
			closing_text_tooltip_content: `Maximum 50 characters. Custom content created here will be automatically inserted at the end of all outbound
			voice calls and SMS. For 2 way messages, closing content will be inserted after your last response option.`,
			timeout_tooltip_content: `Select the time frame that you wish recipients to respond in. Responses received within this time frame will display as
			“On Time” within your reports. Responses received in the hour immediately following your on time response duration will be shown as “Late” within
			your reports. Responses received after the 1 hour buffer period will not be displayed in your reports. You can overwrite this on a per message
			basis if needed.`,
			sms_link_all_messages_tooltip_content: `Global SMS networks do not support using an SMS sender name with traditional 2 way SMS messages.
			Enabling this slider means that a clickable link will be embedded in your SMS message allowing recipients to respond via the link. Because
			the response is not being sent via SMS, you are able to use an SMS sender name with 2 way messages sent whilst this slider is enabled.`,
		}
	},
	computed: {
		...mapGetters([
			'customer_group_permissions',
			'logged_user',
			'customer_group',
		]),
		switch_options() {
			return [
				{
					condition: this.customer_group_permissions.sms_quick_reports,
					label: 'Send reports links via SMS',
					name: 'sms-quick-reports',
					value: 'sms_quick_reports',
					action: 'updateSMSQuickReports',
					tooltip_content: this.sms_quick_reports_tooltip_content,
				},
				{
					condition: true,
					label: `Insert “${this.customer_group.customer_group.session_type_name.test_scenario_name}” header to outbound SMS`,
					name: 'test-message-header',
					value: 'test_message_header',
					action: 'updateTestMessageHeader',
					tooltip_content: this.test_message_header_tooltip_content,
				},
				{
					condition:
						this.show_send_notifications_to_admins_about_invalid_recipients,
					label: 'Send notifications to admins about invalid recipients',
					name: 'send-notifications-to-admins-about-invalid_recipients',
					value: 'send_notifications_to_admins_about_invalid_recipients',
					action: 'updateSendNotificationsToAdminsAboutInvalidRecipients',
					tooltip_content:
						this
							.send_notifications_to_admins_about_invalid_recipients_tooltip_content,
				},
				{
					condition:
						this.customer_group_permissions.two_way_sms_link_permission,
					label: 'Automatically use SMS links based on recipient country code',
					name: 'two-way-sms',
					value: 'two_way_sms',
					action: 'updateTwoWaySMS',
					tooltip_content: this.two_way_sms_tooltip_content,
				},
				{
					condition:
						this.customer_group_permissions.two_way_sms_link_permission,
					label: 'Automatically use SMS links for all messages',
					name: 'force-two-way-sms',
					value: 'force_two_way_sms',
					action: 'updateForceTwoWaySMS',
					tooltip_content: this.sms_link_all_messages_tooltip_content,
				},
			]
		},
		input_options() {
			return [
				{
					label: 'Opening text',
					helper_text:
						'Insert custom opening content to outbound voice calls and SMS',
					name: 'opening-text',
					value: 'opening_text',
					action: 'updateOpeningText',
					tooltip_content: this.opening_text_tooltip_content,
				},
				{
					label: 'Closing text',
					helper_text:
						'Insert custom closing content to outbound voice calls and SMS',
					name: 'closing-text',
					value: 'closing_text',
					action: 'updateClosingText',
					tooltip_content: this.closing_text_tooltip_content,
				},
			]
		},
		default_message_timeout: {
			get() {
				return this.$store.getters.default_message_timeout
			},
			set(val) {
				this.updateDefaultMessageTimeout(val)
			},
		},
	},
	methods: {
		...mapActions(['updateDefaultMessageTimeout']),
	},
	created() {
		this.timeout_choices = helpers.getTimeoutChoices()
		this.show_send_notifications_to_admins_about_invalid_recipients =
			this.customer_group.customer_group.parent == null
	},
}
</script>

